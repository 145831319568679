export default {
  location: {
    rows: [{
      id: 1,
      group_id: '',
      type: 1,
      name: 'โรงพยาบาล',
      children: [],
      remark: '',
      address: 'โรงพยาบาลพระมงกุฎเกล้า :ถนนราชวิถี',
      city: 1,
      subdistrict: 2,
      district: 2,
      region: 1,
      // ไม่มีตัวแปรเหล่านี้ใน diagram
      type_name: 'พื้นที่', 
      city_name: 'กทม.',
      subdistrict_name: 'ทุ่งพญาไท',
      district_name: 'ราชเทวี'
    }, {
      id: 2,
      group_id: '',
      type: 1,
      name: 'ทาวน์โฮม',
      children: [],
      remark: '',
      address: '3 ซ.ลาดพร้าว 101 ซ.46 (กิตติจิตต์)',
      city: 1,
      subdistrict: 1,
      district: 1,
      region: 1,
      // ไม่มีตัวแปรเหล่านี้ใน diagram
      type_name: 'พื้นที่', 
      city_name: 'กทม.',
      subdistrict_name: 'คลองจั่น',
      district_name: 'บางกะปิ'
    }],
    paging: {
      page: 1,
      rowTotal: 2
    }
  },
  types: [{
    id: 1,
    name: 'พื้นที่'
  }, {
    id: 2,
    name: 'วัด'
  }, {
    id: 3,
    name: 'แลนด์มาร์ค'
  }],
  regions: [{
    id: '',
    name: '',
    country: ''
  }],
  // จังหวัด
  cities: [{
    id: 1,
    code: '0001',
    name: 'กทม.',
    region: '',
    country: ''
  }, {
    id: 2,
    code: '0002',
    name: 'โคราช',
    region: '',
    country: ''
  }, {
    id: 3,
    code: '0003',
    name: 'ศรีสะเกษ',
    region: '',
    country: ''
  }],
  // อำเภอ
  districts: [{
    id: 1,
    code: '0001',
    city: '',
    name: 'บางกะปิ',
    region:'',
    country: ''
  }, {
    id: 2,
    code: '0002',
    city: '',
    name: 'ทุ่งพญาไท',
    region:'',
    country: ''
  }, {
    id: 3,
    code: '0003',
    city: '',
    name: 'เมืองโคราช',
    region:'',
    country: ''
  }, {
    id: 4,
    code: '0004',
    city: '',
    name: 'อุทุมพรพิสัย',
    region:'',
    country: ''
  }],
  // ตำบล
  subdistricts: [{
    id: 1,
    code: '0001',
    district: '',
    name: 'คลองจั่น',
    city: '',
    region: '',
    country: ''
  }, {
    id: 2,
    code: '0002',
    district: '',
    name: 'ราชเทวี',
    city: '',
    region: '',
    country: ''
  }, {
    id: 3,
    code: '0003',
    district: '',
    name: 'ปักธงชัย',
    city: '',
    region: '',
    country: ''
  }, {
    id: 4,
    code: '0004',
    district: '',
    name: 'อุทุมพรพิสัย',
    city: '',
    region: '',
    country: ''
  }],
  regions: [{
    id: 1,
    name: 'ภาคกลาง'
  }, {
    id: 2,
    name: 'ภาคตะวันออกเฉียงเหนือ'
  }],
  search_district: {
    district_filters: [{
      filter_count: 0,
      id: 1,
      code: '0001',
      city: '',
      name: 'บางกะปิ',
      region:'',
      country: ''
    }, {
      filter_count: 0,
      id: 2,
      code: '0002',
      city: '',
      name: 'ทุ่งพญาไท',
      region:'',
      country: ''
    }, {
      filter_count: 0,
      id: 3,
      code: '0003',
      city: '',
      name: 'เมืองโคราช',
      region:'',
      country: ''
    }, {
      filter_count: 0,
      id: 4,
      code: '0004',
      city: '',
      name: 'อุทุมพรพิสัย',
      region:'',
      country: ''
    }]
  },
  search_subdistrict: {
    subdistrict_filters: [{
      filter_count: 0,
      id: 1,
      code: '0001',
      district: '',
      name: 'คลองจั่น',
      city: '',
      region: '',
      country: ''
    }, {
      filter_count: 0,
      id: 2,
      code: '0002',
      district: '',
      name: 'ราชเทวี',
      city: '',
      region: '',
      country: ''
    }, {
      filter_count: 0,
      id: 3,
      code: '0003',
      district: '',
      name: 'ปักธงชัย',
      city: '',
      region: '',
      country: ''
    }, {
      filter_count: 0,
      id: 4,
      code: '0004',
      district: '',
      name: 'อุทุมพรพิสัย',
      city: '',
      region: '',
      country: ''
    }]
  }
}
  