<template lang="">
  <div>
    <h6 class="heading-small text-muted mb-4">ข้อมูลสถานที่ตั้ง</h6>
    <div class="pl-lg-4">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="mb-4">
            <label class="form-label">ชื่อ</label>
            <base-input name="name" :rules="{required: true}" placeholder="ชื่อ" v-model="item.name"></base-input>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="mb-4">
            <label class="form-label optional">ประเภทสถานที่ตั้ง</label>
            <Select2 v-model="item.type" :options="types" placeholder="ประเภทสถานที่ตั้ง" />
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="mb-4">
            <label class="form-label optional">ภูมิภาค</label>
            <select class="form-control" v-model="item.region">
              <option v-for="(region) in regions" :key="`region_key_${region.id}`" :value="region.id">
                {{region.text}}
              </option>
            </select>
            <base-input name="region" v-model="item.region" class="custom-input-valid-datepicker"></base-input>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="mb-4">
            <label class="form-label optional">จังหวัด</label>
            <Select2 v-model="item.city" :options="cities" placeholder="จังหวัด"/>
            <base-input name="city" v-model="item.city" class="custom-input-valid-datepicker"></base-input>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="mb-4">
            <label class="form-label optional">เขต/อำเภอ</label>
            <Select2 v-model="item.district" :options="districts" placeholder="เขต/อำเภอ"/>
            <base-input name="district" v-model="item.district" class="custom-input-valid-datepicker"></base-input>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="mb-4">
            <label class="form-label optional">แขวง/ตำบล</label>
            <Select2 v-model="item.subdistrict" :options="subdistricts" placeholder="แขวง/ตำบล" />
            <base-input name="subdistrict" v-model="item.subdistrict" class="custom-input-valid-datepicker"></base-input>
          </div>
        </div>
        <div class="col-md-6">
         
          <div class="mb-4">
            <label class="form-label optional">สถานที่ตั้งหลัก</label>
            <Select2 v-model="item.parent" :options="parents" placeholder="สถานที่ตั้งหลัก"/>
            <base-input name="parent" v-model="item.parent" class="custom-input-valid-datepicker"></base-input>
          </div>
          <div class="mb-4">
            <label class="form-label optional">ที่อยู่</label>
            <b-form-textarea name="address" placeholder="ที่อยู่" v-model="item.address" rows="3" max-rows="5"></b-form-textarea>
            <base-input name="address" v-model="item.address" class="custom-input-valid-datepicker"></base-input>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-4">
            <label class="form-label optional">หมายเหตุ</label>
            <b-form-textarea name="remark" placeholder="หมายเหตุ" v-model="item.remark" rows="3" max-rows="5"></b-form-textarea>
            <base-input name="remark" v-model="item.remark" class="custom-input-valid-datepicker"></base-input>
          </div>
        </div>
      </div>
      <hr class="my-4">
    </div>
  </div>
</template>
<script>
import mock from '../mock/location';
export default {
  name: 'location-view-form-location',
  data () {
    return {
      types: [],
      cities: [],
      districts: [],
      subdistricts: [],
      changed: false,
      mock: mock,
      regions: [],
      parents: []
    }
  },
  props: [
    'item'
  ],
  methods: {
    async getTypes () {
      this.types = await this.HttpServices.getMasterData("/master/getLocationTypes");
      this.types.unshift({id: "0", text: "เลือกประเภทสถานที่ตั้ง"});
      if(this.types.length>0){
        this.item.type = this.types[0].id;
      }
    },
    async getRegion () {
      this.regions = await this.HttpServices.getMasterData("/master/getRegion");
    },
    async getCities () {
      this.cities = await this.HttpServices.getMasterData(`/master/getCity?region=${this.item.region}`);
    },
    async getDistricts () {
      const params = `?city=${this.item.city}`;
      this.districts = await this.HttpServices.getMasterData(`/master/getDistrict${params}`);
    },
    async getSubDistricts () {
      const params = `?city=${this.item.city}&district=${this.item.district}`;
      this.subdistricts = await this.HttpServices.getMasterData(`/master/getSubDistrict${params}`);
      this.changed = true;
    },
    async getParents () {
      //this.parents = await this.HttpServices.getMasterData("/master/getLocations");
    },
  },
  async mounted () {
    await this.getTypes();
    await this.getRegion();
    await this.getParents();
    await this.getCities();
    // await this.getDistricts();
    // await this.getSubDistricts();
  },
  watch:{
    'item.region'(newVal){
      if(newVal){
        this.getCities();
      }else{
        this.cities = [];
        this.districts = [];
        this.subdistricts = [];
      }
      if(this.changed){
        this.item.city = "";
        this.item.district = "";
        this.item.subdistrict = "";
      }
    },
    'item.city'(newVal){
      if(newVal){
        this.getDistricts();
      }else{
        this.districts = [];
        this.subdistricts = [];
      }
      if(this.changed){
        this.item.district = "";
        this.item.subdistrict = "";
      }
    },
    'item.district'(newVal){
      if(newVal){
        this.getSubDistricts();
      }else{
        this.subdistricts = [];
      }

      if(this.changed){
        this.item.subdistrict = "";
      }
    }
  }
}
</script>
